$stateGreen: #004D44;
$stateGold: #A39161;
$stateDarkGrey: #565152;
$stateLightGrey: #EDECE5;
$stateGoldAA: #83764E;
$stateAlertRed: #AE1122;
$stateActGreen: #009C3C;
$stateLinkBlue: #2C55A2;
$stateLimeGreen: #D8E7B9;
$stateAlertYellow: #FFCF21;
$govMidnight: #00241A;
$white: #ffffff;
$brightTurquoise: #00c5ff;