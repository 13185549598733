@import './variables.scss';

.content-wrapper {
    a {
        text-decoration: none;
    }
    .content-link {
    transition: ease-in-out .3s;
    
    &:hover {
        cursor: pointer;
        background-color: $stateLightGrey;
    }
}
}