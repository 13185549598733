@import './variables.scss';

body {
  font-family: 'Lato' !important;
}

.navbar {
  background-color: $stateGreen;
  color: $white !important;
}
.navbar-brand {
  color: $white !important;
  img {
    height: 50px;
  }
}
.navbar-togger-icon,
.nav-link {
  color: $white !important;
}

.nav-item {
  font-size: 14px !important;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  border-width: 2px !important;
  border-radius: 0 !important;
}

.btn {
  border-radius: 0 !important;
}

.react-datepicker__triangle::after,
.react-datepicker__triangle::before {
  left: -60px !important;
}

.react-datepicker__month-read-view--selected-month {
  margin-left: -12px !important;
}
.react-datepicker__year-read-view--selected-year {
  margin-right: -12px !important;
}

// Override bootstrap default colors
$primary: $stateGreen;

// Import bootstrap styles at the bottom
@import '~bootstrap/scss/bootstrap.scss';
@import 'lato-font/css/lato-font.css';
