#details {
  height: 8rem;
}

#main {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

@media screen and (max-width: 768px) {
  #main {
    width: 95%;
  }
}
